import { Button, Input, Table, Tag } from "antd";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import { notifyError } from "src/components/notification";
import TableStyled from "src/components/table/TableStyled";
import { _apiNotSaga } from "src/services/api/apiNotSaga";
import { replaceSpecialCharacters } from "src/utils/functions";

const Tracuuvandon = () => {
	const [searchTxt, setSearchTxt] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState<any>({});
	const handleSearch = () => {
		// router.push({
		//   pathname: `/tra-van-don`,
		//   query: {q: searchTxt},
		// })

		setIsLoading(true);
		const initcareer = async () => {
			try {
				// if(!stateGetOrgs?.data || !stateGetRoles?.data) return;
				setIsLoading(true);
				const res: any = await _apiNotSaga.fetchSearchEs(searchTxt, {});
				setData(res?.data);
				setIsLoading(false);
			} catch (error: any) {
				setIsLoading(false);
				notifyError(error.message);
			}
		};
		initcareer();
	};
	const columns = [
		{
			title: "Thời gian",
			dataIndex: "created_at",
			key: "created_at",
			render: (text: any) => <p className="text-left font-medium">{moment(text).format("DD/MM/YYYY HH:mm")}</p>
		},

		{
			title: "Hành trình",
			dataIndex: "bill_status_name",
			key: "bill_status_name",
			render: (text: any, record: any) => (
				<div className="flex flex-col">
					<p className="hidden text-left text-black font-medium">{text}</p>
					{/* <p className="text-left font-medium">
                {record?.district} - {record?.city}
              </p> */}
					<p className="text-left font-medium">{record?.desc_history}</p>
				</div>
			)
		}
	];
	return (
		<>
			<div className="relative flex h-[350px] w-full flex-col items-center justify-start">
				{/* <div
					style={{
						backgroundImage: "url(/tracuu.png)",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center",
						backgroundSize: "cover"
					}}
					className="absolute left-0 top-0 flex h-[350px] w-full items-center justify-center max-md:h-[350px] "
				></div> */}
				<div className="container flex flex-col items-center justify-center md:px-5">
					<div className=" z-10 flex w-10/12 flex-col items-center justify-center p-5 pt-[70px] max-md:w-11/12 max-md:p-2">
						<p className={`px-4 text-center text-24 font-medium text-black max-md:px-2 max-md:text-15`}>
							Tra cứu vận đơn
						</p>
						<div className="flex w-full items-center space-x-4 max-md:space-x-2">
							<Input
								type="text"
								value={searchTxt}
								className="h-[55px] rounded-md bg-bginput text-[20px] max-md:h-[44px] max-md:text-15"
								onChange={(e) => setSearchTxt(replaceSpecialCharacters(e.target.value))}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handleSearch();
									}
								}}
								placeholder={"Nhập mã vận đơn"}
							/>
							<Button
								onClick={() => handleSearch()}
								// type="submit"
								// size={"search"}
								className="inline-flex h-[55px] w-[137px] items-center justify-center gap-2.5  rounded-md  bg-black px-[13px] py-2 text-white max-md:h-[44px]"
							>
								<p className="text-center text-[20px] font-medium leading-normal">Tìm kiếm</p>
							</Button>
						</div>
						{/* <div className="flex w-full justify-between">
							<p className="text-black max-md:text-13">
								Hotline:{" "}
								<a href="tel:(028) 3848 0642">
									<span className="font-medium text-black">(028) 3848 0642</span>
								</a>{" "}
								hoặc Email:{" "}
								<a href="mailto:info@lsc.com">
									<span className="font-medium text-black">info@lsc.com</span>
								</a>
							</p>
						</div> */}
					</div>
					<div className="flex w-full flex-col items-center justify-center pt-[50px] max-md:w-11/12 ">
						<div className="container flex w-full items-center justify-center">
							{data?.bill_code && (
								<div className="flex w-full flex-col items-center justify-center">
									<div className="mt-4 flex w-full flex-col rounded-[12px] bg-white p-5 shadow-2xl">
										<div className="flex w-full items-center">
											<p className="text-20 font-medium text-Gray_4">
												<span className="text-nhattin">{data?.bill_code}</span>
											</p>
											{/* eslint-disable no-nested-ternary  */}
											<Tag
												// bordered={false}
												className="ml-2"
												color={
													[1, 3, 4, 5, 8].includes(data?.bill_status_id)
														? "warning"
														: [6, 7].includes(data?.bill_status_id)
														? "processing"
														: "success"
												}
											>
												<p className="font-medium">{data.bill_status_name}</p>
											</Tag>
											{/* <Link target="_blank" to={`/tra-van-don.html?bill=${data.bill_code}`}>
												<img src="/svg/statics/share.svg" />
											</Link> */}
										</div>

										<div className="grid w-full grid-cols-2 gap-4 max-md:grid-cols-1">
											<div className="col-span-1">
												<p className="text-14 py-2 font-medium text-[#9AA2AB]">
													Ngày gửi: {moment(data?.created_at).format("DD/MM/YYYY HH:mm")}:
												</p>
												<div className="flex py-2 text-[#001529] justify-start w-full items-center">
													<div className="flex flex-col justify-start">
														<p className="text-16 font-medium">{data?.s_province_name}</p>
														<p className="text-12">{data?.s_district_name}</p>
													</div>
													<img className="px-[50px]" src="/trucks.svg" />
													<div className="flex flex-col justify-start">
														<p className="text-16 font-medium">{data?.r_province_name}</p>
														<p className="text-12">{data?.r_district_name}</p>
													</div>
												</div>
												<p className={`text-[#001529] py-2 text-16 font-medium`}>Thông tin người nhận</p>
												<div className="grid grid-cols-2 gap-4">
													<div className="col-span-1">
														<div className="flex items-center py-2 justify-start ">
															<p className="text-14 text-[#606060]">Họ tên: </p>
															<p className="text-14 font-medium pl-2">{data?.r_name || ""}</p>
														</div>
														<div className="flex items-center py-2 justify-start font-medium">
															<p className="text-14 text-[#606060]">Địa chỉ: </p>
															<p className="text-14 font-medium pl-2">
																{data?.r_ward_name}, {data?.r_district_name}, {data?.r_province_name}
															</p>
														</div>
														<div className="flex items-center py-2 justify-start font-medium">
															<p className="text-14 text-[#606060]">SĐT: </p>
															<p className="text-14 font-medium pl-2">{data?.r_phone || ""}</p>
														</div>
													</div>
													<div className="col-span-1 flex justify-start">
														{/* <div className="flex h-[180px] w-[30px] flex-col items-center justify-center">
                          <div className="flex h-[33px] w-[33px] items-center justify-center">
                            {!item.found ? (
                              <div className="h-[12px] w-[12px]  rounded-[6px] bg-Gray_4" />
                            ) : item.current_step_status === "step_1" ? (
                              <img src="/assets/images/package.svg" />
                            ) : (
                              <div className="h-[12px] w-[12px]  rounded-[6px] bg-Green_3" />
                            )}
                          </div>

                          <div className="h-[40px] w-[1px] bg-black" />
                          <div className="flex h-[33px] w-[33px] items-center justify-center">
                            {!item.found ? (
                              <div className="h-[12px] w-[12px]  rounded-[6px] bg-Gray_4" />
                            ) : item.current_step_status === "step_2" ? (
                              <img src="/assets/svg/truck.svg" />
                            ) : (
                              <div
                                className={`h-[12px] w-[12px]  rounded-[6px] ${
                                  item.current_step_status === "step_3"
                                    ? "bg-Green_3"
                                    : "bg-Main_Color_1"
                                } `}
                              />
                            )}
                          </div>

                          <div className="h-[40px] w-[1px] bg-black" />
                          <div className="flex h-[33px] w-[33px] items-center justify-center">
                            {!item.found ? (
                              <div className="h-[12px] w-[12px]  rounded-[6px] bg-Gray_4" />
                            ) : item.current_step_status === "step_3" ? (
                              <img src="/assets/images/tick.svg" />
                            ) : (
                              <div className="h-[12px] w-[12px]  rounded-[6px] bg-Main_Color_1" />
                            )}
                          </div>
                        </div>
                        <div
                          className={`flex h-[180px] flex-col items-start justify-between px-5 ${
                            !item.found ? "text-Gray_4" : "text-black"
                          }`}
                        >
                          <div className="mt-2 h-[33px]">
                            <p className="text-12 font-medium">
                              {trans.tracuu.dalayhang}
                            </p>
                          </div>
                          <div className="h-[40px]" />
                          <div className="h-[33px]">
                            <p className="text-12 font-medium">
                              {trans.tracuu.dangvanchuyen}
                            </p>
                          </div>
                          <div className="h-[40px]" />
                          <div className="h-[33px]">
                            <p className="text-12 font-medium">
                              {trans.tracuu.dagiao}
                            </p>
                          </div>
                        </div> */}
													</div>
												</div>
											</div>
											<div className="col-span-1">
												<div className="flex flex-col items-start">
													<div className="flex  justify-start">
														<p className={`font-medium text-grays`}>Dịch vụ:</p>
														<p className={`px-2 font-medium text-Sub_Color_1 `}>{data.service_name}</p>
													</div>
													{/* <div className="flex  justify-start">
                        <p className={`font-medium text-grays`}>
                          {trans.tracuu.dukien}:
                        </p>
                        <p className={`px-2 font-medium text-Sub_Color_1 `}>
                          {moment(data.date_expected).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </p>
                      </div> */}
													{/* {item?.delay_case_ship?.length > 0 && (
                        <div className="flex  justify-start">
                          <p className={`font-medium text-grays`}>
                            {trans.tracuu.sucogiaohang}:
                          </p>
                          <div className="flex items-center justify-start">
                            <p className={`px-2 font-medium text-Sub_Color_1 `}>
                              {item?.delay_case_ship[0]?.reason} -{" "}
                              {moment(item?.delay_case_ship[0]?.time).format(
                                "HH:mm DD/MM/YYYY"
                              )}
                            </p>
                            {item?.delay_case_ship?.length > 1 && (
                              <Popover
                                content={
                                  <div className="flex flex-col">
                                    {item?.delay_case_ship
                                      ?.slice(1)
                                      .map((item1: any, index1: number) => (
                                        <p
                                          key={index1}
                                          className={`py-2 font-medium text-Sub_Color_1 `}
                                        >
                                          {item1.reason} -{" "}
                                          {moment(item1?.time).format(
                                            "HH:mm DD/MM/YYYY"
                                          )}
                                        </p>
                                      ))}
                                  </div>
                                }
                                title="Danh sách sự cố"
                                trigger="hover"
                              >
                                <p className={`px-2 font-medium text-link `}>
                                  (Xem thêm)
                                </p>
                              </Popover>
                            )}
                          </div>
                        </div>
                      )} */}
												</div>
												<div className="flex w-full flex-col justify-start">
													<p className="text-15 font-normal text-[#212E3B]">Hành trình vận đơn</p>
													{data?.log?.length === 0 ? (
														<div className="mb-5 overflow-hidden rounded-[10px] border border-border">
															<p>Không tìm thấy hành trình</p>
														</div>
													) : (
														<div className="mb-5  rounded-[10px] border border-border">
															<TableStyled
																rowKey="index"
																bordered
																columns={columns}
																isRowLight={true}
																pagination={false}
																dataSource={data?.log?.reverse() || []}
																// showHeader={false}
																// style={{ marginLeft: "58px" }}
																sticky={false}
															/>
															{/* <Table
																columns={columns}
                                                                className="thisIsAnShit"
																dataSource={data?.log?.reverse() || []}
																pagination={false}
																showHeader={true}
																// scroll={{ y: 200 }}
															/> */}
														</div>
													)}

													{/* <div className="flex w-full items-center justify-between bg-Main_Color_3 p-2 max-md:flex-col">
                        <div className="flex items-center justify-start max-md:w-full">
                          <img src="/assets/svg/logoico.svg" />
                          <p className="pl-4 text-15 text-black">
                            <span className="font-medium">
                              {trans.tracuu.tracking}{" "}
                            </span>
                            <br />
                            {trans.function.tracuu}, {trans.tracuu.trackdes}
                          </p>
                        </div>
                        <Link target="_blank" href={APP_STORE.android}>
                          <Button className="bg-black text-nhattin max-md:mt-2 max-md:w-full">
                            <Download className="mr-2 h-4 w-4" />{" "}
                            {trans.tracuu.taiapp}
                          </Button>
                        </Link>
                      </div> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							)}
							{data?.length === 0 && <img src="/assets/images/page/notfound.png" />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Tracuuvandon;

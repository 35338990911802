import axios from "axios";
import { api } from "./api.index";
import { API_BO, API_PRICE_URL, API_URL, API_URL_ERP, CND_URL, REACT_APP_CDN_REACT_TOKEN } from "./config";
import { Md5 } from 'ts-md5';
import {
	API_BANKS,
	API_BANK_BRANCHS,
	API_DISTRICTS,
	API_DRIVERS,
	API_ENUM,
	API_ERP,
	API_MAINTENANCES,
	API_MODULE_FUNT,
	API_MODULE_FUNT_IDX,
	API_PARTNER,
	API_WAREHOUSES,
	API_PRICE_OFF_CATEGORIES,
	API_PRICE_OFF_TYPE,
	API_PROVINCES,
	API_REPAIR_REGULATION,
	API_ROAD_COST_MANAGEMENT,
	API_ROAD_COST_STATION,
	API_ROLES,
	API_ROUTES,
	API_ROUTING_GROUP,
	API_ROUTING_ROADS_WH,
	API_SUPPLIES_MANAGEMENT,
	API_TRANSPORT_TRIPS,
	API_USERS,
	API_VEHICLES,
	API_VEHICLE_DIARY,
	API_VEHICLE_GROUP,
	API_VEHICLE_TYPE,
	API_WARDS,
	API_TRANSPORT_BOOKINGS,
	API_TRANSPORT_AUTO_CONFIG,
	API_DASHBOARD_VEHICLES,
	API_REPORT_IDENTIFIER,
	API_TRANSPORT_BOOKING_DETAILS,
	API_DSS,
	API_EUP,
	API_TRIP_COST,
	API_TRIP_EXPENSE,
	API_STATEMENT_TRIP_COST,
	API_TRANSPORT_TRIP_COST,
	API_TRANSPORT_TRIP_DETAILS,
	API_TRANSPORT_TRIP_CONFIG_KPI,
	API_TRANSPORT_BOOKING_INCURREDS,
	API_VEHICLE_GROUP_WEIGHT_CONFIG,
	API_TRANSPORT_BOOKING_DRAFTS,
	API_NOTIFICATION,
	TRANSPORT_TRIP_EXPORTED,
	API_PARTNER_LOG,
	API_DEPARTEMEN_BLOCK,
	API_CAREER,
	API_DEPARTMENT,
	API_ROLES_FUNCTION,
	API_BANNER,
	API_PAGES,
	API_RECRUIMENT_TICKET,
	API_ZONES,
	API_WORKLOCATION,
	API_POSITION,
	API_POSTTYPE,
	API_POSTNEWS,
	API_EMAIL_FIELD,
	API_CHECKIN_YEP,
	API_EMAIL,
	API_EDUCATION,
	API_RECRUIMENT_NEW,
	API_RECRUIMENT_NEW_SEO,
	API_USERS_ORG,
	API_CANDIDATE,
	API_LOGOUT,
	API_CANDIDATE_PROFILE,
	API_CANDIDATE_LOG,
	API_CANDIDATE_EVALUTE,
	API_LEVEL,
	API_CANDIDATERESULT,
	API_STATUS_PROFILE,
	API_POSTOFFICE,
	API_APPS,
	API_USERAPPS,
	API_ORG,
	API_USER_GROUPS,
	API_USER_ERP,
	API_POST_OFFICE,
	API_SUB_DEPARTMENT,
	API_BILLS,
	API_WAREHOUSE,
	API_SERVICES,
	API_GOOD_TYPES,
	API_PARTNERS,
	API_PARTNER_ADDRESS,
	API_CREATE_BILL,
	API_CREATE_OPERATION_WH,
	API_GET_USERS,
	API_GET_WAREHOUSE_LIST,
	API_SCAN_BILL_IN_OUT,
	API_SCAN_ONE_BILL_IN_OUT,
	API_COMPLETE_OPERATION_WH,
	API_UPDATE_OPERATION_WH,
	API_PRINT_BILLS,
	SEARCHES,
	API_DELETE_BILLS_IN_OUT,
	API_CANCEL_BILL,
	API_SHOW_BY_CODE,
	API_GET_PRICE_LIST,
	API_CREATE_PRICE_LIST,
	API_GET_PRICE_DETAIL_LIST,
	// API_ROUTING_CUTOFF_TIME
} from "./url.index";
export function generatorToken(string: any) {
	if (!string) {
	  return '';
	}
	const MD5 = Md5.hashStr(`mobileappwY7JaAYtAqW3EjRt${JSON.stringify(string)}`);
	return MD5;
  }
export const _apiNotSaga = {
	getAllVehicleGroup: (params: any) => api.get(`${API_URL}/${API_VEHICLE_GROUP}`, params),
	getVehicleType: (params: any) => api.get(`${API_URL}/${API_VEHICLE_TYPE}`, params),
	getVehicleTypeDetail: (id: any) => api.get(`${API_URL}/${API_VEHICLE_TYPE}/${id}`),

	//Vehicle
	getVehicles: (params: any) => api.get(`${API_URL}/${API_VEHICLES}`, params),
	getVehicleStatus: (params: any) => api.get(`${API_URL}/${API_VEHICLES}/vehicle-status`, params),
	getVehicleDetail: (id: any) => api.get(`${API_URL}/${API_VEHICLES}/${id}`),
	locationVehicleById: (id: any, params:any) => api.get(`${API_URL}/${API_VEHICLES}/get-location/${id}`,params),
	createVehicle: (params: any) => api.postMultipart(`${API_URL}/${API_VEHICLES}`, params),
	updateVehicle: (id: number, params: any) => api.putMultipart(`${API_URL}/${API_VEHICLES}/${id}`, params),
	addDriverInVehicle: (params: any) => api.post(`${API_URL}/${API_VEHICLES}/add-driver-in-vehicle`, params),

	getAllRoutingGroups: (params: any) => api.get(`${API_URL}/${API_ROUTING_GROUP}`, params),
	getRoutingGroupsDetail: (id: any) => api.get(`${API_URL}/${API_ROUTING_GROUP}/${id}`),
	createRoutingGroups: (params: any) => api.post(`${API_URL}/${API_ROUTING_GROUP}`, params),
	updateRoutingGroups: (id: number, params: any) => api.put(`${API_URL}/${API_ROUTING_GROUP}/${id}`, params),

	getDriverDetail: (id: any) => api.get(`${API_URL}/${API_DRIVERS}/${id}`),
	createDriver: (formData: any) => api.postMultipart(`${API_URL}/${API_DRIVERS}`, formData),
	updateDriver: (id: any, params: any) => api.putMultipart(`${API_URL}/${API_DRIVERS}/${id}`, params),
	getHistoryVehicle: (params: any) => api.get(`${API_URL}/${API_DRIVERS}/history-vehicle`,params),

	getRoles: (params: any) => api.get(`${API_URL}${API_ROLES}`, params),
	getEnum: (params: any) => api.get(`${API_URL}/${API_ENUM}`, params),
	updateRole: (id: any, params: any) => api.put(`${API_URL}${API_ROLES}/${id}`, params),
	roleDetail: (id: any) => api.get(`${API_URL}${API_ROLES}/${id}`),
	createRole: (params: any) => api.post(`${API_URL}${API_ROLES}`, params),

	getRoadCostStation: (params: any) => api.get(`${API_URL}/${API_ROAD_COST_STATION}`, params),
	getReportIdentifier: (params: any) => api.get(`${API_URL}/${API_REPORT_IDENTIFIER}`, params),
	exportReportIdentifier: (params: any) => api.get(`${API_URL}/${API_REPORT_IDENTIFIER}/template`, params),

	getRealtimeEUP: (params: any) => api.get(`${API_URL}/${API_EUP}/get-vehicles-realtime`, params),
	getDetailRealtimeEUP: (params: any) => api.get(`${API_URL}/${API_EUP}/get-detail-vehicles-realtime`, params),
	getRealtimeDSS: (params: any) => api.get(`${API_URL}/${API_DSS}/get-vehicles-realtime`, params),
	getLogDSS: (params: any) => api.get(`${API_URL}/${API_DSS}/get-log-vehicles`, params),
	getLogEUP: (params: any) => api.get(`${API_URL}/${API_EUP}/get-log-vehicle`, params),
	getDetailRealtimeDSS: (params: any) => api.get(`${API_URL}/${API_DSS}/get-detail-vehicles-realtime`, params),
	getRoadCostByCars: (params: any) => api.get(`${API_URL}/${API_EUP}/get-road-cost-by-cars`, params),
	
	getRealtimeHistory: (params: any) => api.get(`${API_URL}/${API_REPORT_IDENTIFIER}/realtimeHis`, params),
	// checkUserCode: (params: any) => api.post(`https://ws.ntlogistics.vn:14443/NTLIntegration/`, params),
	createPriceOffType: (params: any) => api.post(`${API_URL}/${API_PRICE_OFF_TYPE}`, params),
	updatePriceOffType: (id: number, params: any) => api.put(`${API_URL}/${API_PRICE_OFF_TYPE}/${id}`, params),

	getRoutes: (params: any) => api.get(`${API_URL}/${API_ROUTES}`,params),
	createRoutes: (params: any) => api.post(`${API_URL}/${API_ROUTES}`, params),
	getRouteDetail: (id: any) => api.get(`${API_URL}/${API_ROUTES}/${id}`),
	updateRoute: (id: any, params: any) => api.put(`${API_URL}/${API_ROUTES}/${id}`, params),

	getMaintenances: (params: any) => api.get(`${API_URL}/${API_MAINTENANCES}`, params),
	createMaintenances: (params: any) => api.postMultipart(`${API_URL}/${API_MAINTENANCES}`, params),
	updateMaintenances: (id: any, params: any) => api.putMultipart(`${API_URL}/${API_MAINTENANCES}/${id}`, params),
	deleteMaintenances: (id: any) => api.delete(`${API_URL}/${API_MAINTENANCES}/${id}`),
	getMaintenancesDetail: (id: number, params?: any) => api.get(`${API_URL}/${API_MAINTENANCES}/${id}`, params),
	updateMaintenancesRepairRule: (id: any, params: any) =>
		api.put(`${API_URL}/${API_MAINTENANCES}/repair_ruler/${id}`, params),
	createMaintenancesRepairRule: (id: any, params: any) =>
		api.post(`${API_URL}/${API_MAINTENANCES}/repair_ruler/${id}`, params),
	deleteMaintenancesRepairRule: (params: any) => api.delete(`${API_URL}/${API_MAINTENANCES}/repair_ruler`, params),
	createMaintenancesRepairSupplie: (params: any) => api.post(`${API_URL}/${API_MAINTENANCES}/repair_supplie`, params),
	updateMaintenancesRepairSupplie: (id: any, params: any) =>
		api.put(`${API_URL}/${API_MAINTENANCES}/repair_supplie/${id}`, params),
	deleteMaintenancesRepairSupplie: (id: any, params: any) =>
		api.delete(`${API_URL}/${API_MAINTENANCES}/repair_supplie/${id}`, params),
	getMaintenancesRepairSupplie: (params: any) => api.get(`${API_URL}/${API_MAINTENANCES}/repair_supplie`, params),

	getPriceOffCategories: (params: any) => api.get(`${API_URL}/${API_PRICE_OFF_CATEGORIES}`, params),
	priceOffCategoryDetail: (id: any) => api.get(`${API_URL}/${API_PRICE_OFF_CATEGORIES}/${id}`),
	createPriceOffCategories: (params: any) => api.post(`${API_URL}/${API_PRICE_OFF_CATEGORIES}`, params),
	updatePriceOffCategory: (id: any, params: any) => api.put(`${API_URL}/${API_PRICE_OFF_CATEGORIES}/${id}`, params),

	getRepairRuler: (params: any) => api.get(`${API_URL}/${API_REPAIR_REGULATION}`, params),
	getRepairSupples: (params: any) => api.get(`${API_URL}/${API_SUPPLIES_MANAGEMENT}`, params),
	getDriver: (params: any) => api.get(`${API_URL}/${API_DRIVERS}`, params),

	getVehicleDiaries: (params: any) => api.get(`${API_URL}/${API_VEHICLE_DIARY}`, params),
	createVehicleDiary: (formData: any) => api.postMultipart(`${API_URL}/${API_VEHICLE_DIARY}`, formData),
	updateVehicleDiary: (id: any, params: any) => api.putMultipart(`${API_URL}/${API_VEHICLE_DIARY}/${id}`, params),
	vehicleDiaryDetail: (id: any) => api.get(`${API_URL}/${API_VEHICLE_DIARY}/${id}`),
	getOrgs: (params: any) => api.get(`${API_URL}/${API_ERP}/orgs`, params),
	// getPartners: (params: any) => api.get(`${API_URL}/${API_PARTNER}`, params),
	getUserByCode: (code: string) => api.get(`${API_URL}/${API_ERP}/user-by-code/${code}`),

	//tạo mới module-funct
	createModuleFunct: (params: any) => api.post(`${API_URL}/${API_MODULE_FUNT}`, params),
	updateModuleFunct: (id: any, params: any) => api.put(`${API_URL}/${API_MODULE_FUNT}/${id}`, params),
	updateModuleFunctIdx: (params: any) => api.put(`${API_URL}/${API_MODULE_FUNT_IDX}`, params),
	getAllFunctsAction: () => api.get(`${API_URL}/${API_MODULE_FUNT}/all-functs-action`),

	createUser: (params: any) => api.post(`${API_URL}${API_USERS}`, params),
	updateUser: (id: any, params: any) => api.put(`${API_URL}${API_USERS}/${id}`, params),
	getUsersApp: (params: any) => api.get(`${API_URL}/${API_USERAPPS}`, params),
	userDetail: (id: any) => api.get(`${API_URL}${API_USERS}?user_code=${id}`),
	getCurrentUser: () => api.get(`${API_URL}/${API_USERS}/current`),
	getListUser: (params?: any) => api.get(`${API_URL}/${API_USERS}/list`,params),

	getBanks: () => api.get(`${API_URL}/${API_BANKS}`),
	getBankBranch: (params: any) => api.get(`${API_URL}/${API_BANK_BRANCHS}`, params),

	createRepairPartner: (params: any) => api.post(`${API_URL}/${API_PARTNER}`, params),
	updateRepairPartner: (id: any, params: any) => api.put(`${API_URL}/${API_PARTNER}/${id}`, params),
	getRepairPartnerById: (id: any) => api.get(`${API_URL}/${API_PARTNER}/${id}`),


	getPlaces: (params: any) => api.get(`${API_URL}/${API_WAREHOUSES}`, params),
	createPlace: (params: any) => api.post(`${API_URL}/${API_WAREHOUSES}`, params),
	updatePlace: (id: any, params: any) => api.put(`${API_URL}/${API_WAREHOUSES}/${id}`, params),
	getPlaceById: (id: any) => api.get(`${API_URL}/${API_WAREHOUSES}/${id}`),
	
	getRoutingRoadPlaces: (params: any) => api.get(`${API_URL}/${API_ROUTING_ROADS_WH}`, params),

	// getRoutingCutoffTime: (params: any) => api.get(`${API_URL}/${API_ROUTING_CUTOFF_TIME}`, params),

	getTransportTrips: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}`, params),
	getListTripForDraft: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}/get-list-for-draft`, params),
	getTimeExpectedOfPlaces: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}/time-expected-of-places`, params),
	getReportOntime: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}/get-report-ontime`, params),
	getReportPerformance: (params: any) => api.get(`${API_URL}/${API_VEHICLES}/get-report-performance`, params),
	getReportProductivity: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}/get-report-productivity`, params),
	// getReportProblem: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIP_PROBLEMS}/get-report-problem`, params),
	getTransportTripById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIPS}/${id}`),
	createTransportTrip: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_TRIPS}`, params),
	createTransportTripInner: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_TRIPS}/create-inner`, params),
	cancelTransportTrip: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIPS}/cancel/${id}`, params),
	approvalTransportTrip: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIPS}/approval/${id}`, params),
	updateTransportTripDetails: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIP_DETAILS}/${id}`, params),
	finishTransportTrip: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIPS}/start-finish/${id}`, params),
	splitBookTrip: (id: number, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIPS}/split-book-trip/${id}`, params),
	updateTransportTrip: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIPS}/${id}`, params),
	addIncurredWarehouse: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIP_DETAILS}/add-incurred-wh/${id}`, params),
	getWarehouseCurrent: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIP_DETAILS}/get-current-warehouse/${id}`),

	getTransportBookings: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKINGS}`, params),
	checkExistBooking: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKINGS}/check-exits-booking`, params),
	getSplitBooking: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKINGS}/get-split-booking`, params),
	getSplitBookingByWarehouses: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKINGS}/get-split-booking-by-whs`, params),
	getTransportBookingById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKINGS}/${id}`),
	createTransportBooking: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_BOOKINGS}`, params),
	updateTransportBooking: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_BOOKINGS}/${id}`, params),

	getTransportBookingIncurreds: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKING_INCURREDS}`, params),
	getTransportBookingIncurredById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKING_INCURREDS}/${id}`),
	createTransportBookingIncurred: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_BOOKING_INCURREDS}`, params),
	updateTransportBookingIncurred: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_BOOKING_INCURREDS}/${id}`, params),

	getTransportBookingDrafts: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKING_DRAFTS}`, params),
	getTransportBookingDraftById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKING_DRAFTS}/${id}`),
	createTransportBookingDraft: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_BOOKING_DRAFTS}`, params),
	updateTransportBookingDraft: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_BOOKING_DRAFTS}/${id}`, params),
	splitWeightDraft: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_BOOKING_DRAFTS}/split-weight/${id}`, params),

	getVehicleGroupWeightConfigs: (params?: any) => api.get(`${API_URL}/${API_VEHICLE_GROUP_WEIGHT_CONFIG}`, params),
	getVehicleGroupWeightConfigById: (id: any) => api.get(`${API_URL}/${API_VEHICLE_GROUP_WEIGHT_CONFIG}/${id}`),
	createVehicleGroupWeightConfig: (params: any) => api.post(`${API_URL}/${API_VEHICLE_GROUP_WEIGHT_CONFIG}`, params),
	updateVehicleGroupWeightConfig: (id: any, params: any) => api.put(`${API_URL}/${API_VEHICLE_GROUP_WEIGHT_CONFIG}/${id}`, params),

	getTransportBookingDetailById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_BOOKING_DETAILS}/${id}`),

	getTransportAutoConfigs: (params?: any) => api.get(`${API_URL}/${API_TRANSPORT_AUTO_CONFIG}`, params),
	createTransportAutoConfig: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_AUTO_CONFIG}`, params),
	updateTransportAutoConfig: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_AUTO_CONFIG}/${id}`, params),
	getTransportAutoConfigById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_AUTO_CONFIG}/${id}`),

	getTripsFromDashboard: (params?: any) => api.get(`${API_URL}/${API_DASHBOARD_VEHICLES}/trips`, params),
	getDriversFromDashboard: (params?: any) => api.get(`${API_URL}/${API_DASHBOARD_VEHICLES}/drivers`, params),
	configVehicleInTrip: (params?: any) => api.put(`${API_URL}/${API_DASHBOARD_VEHICLES}/config-vehicle`, params),
	configDriverInTrip: (params?: any) => api.put(`${API_URL}/${API_DASHBOARD_VEHICLES}/config-driver`, params),
	
	getTripCostById: (id: number) => api.get(`${API_URL}/${API_TRIP_COST}/${id}`),
	
	getAllTripExpense: (params: any) => api.get(`${API_URL}/${API_TRIP_EXPENSE}`, params),
	getAllStatementTripCosts: (params: any) => api.get(`${API_URL}/${API_STATEMENT_TRIP_COST}`, params),
	createStatementTripCost: (params: any) => api.post(`${API_URL}/${API_STATEMENT_TRIP_COST}`, params),
	createPaymetTicket: (params: any) => api.post(`${API_URL}/${API_STATEMENT_TRIP_COST}/syscTicket`, params),
	getStatementTripCostById: (id: number) => api.get(`${API_URL}/${API_STATEMENT_TRIP_COST}/${id}`),
	updateStatementTripCost: (id: any, params: any) => api.put(`${API_URL}/${API_STATEMENT_TRIP_COST}/${id}`, params),

	getAllTripCost: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIP_COST}`, params),
	getAllTripConfigKpi: (params: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIP_CONFIG_KPI}`, params),
	getTripConfigKpiById: (id: any) => api.get(`${API_URL}/${API_TRANSPORT_TRIP_CONFIG_KPI}/${id}`),
	createTripConfigKpi: (params: any) => api.post(`${API_URL}/${API_TRANSPORT_TRIP_CONFIG_KPI}`, params),
	updateTripConfigKpi: (id: any, params: any) => api.put(`${API_URL}/${API_TRANSPORT_TRIP_CONFIG_KPI}/${id}`, params),
	deleteTripConfigKpi: (id: any) => api.delete(`${API_URL}/${API_TRANSPORT_TRIP_CONFIG_KPI}/${id}`),

	getListNotifications: (params: any) => api.get(`${API_URL}/${API_NOTIFICATION}`, params),
	updateNoti: (id: any, params: any) => api.put(`${API_URL}/${API_NOTIFICATION}/${id}`, params),
	sendNoti: (params: any) => api.post(`${API_URL}/${API_NOTIFICATION}`, params),

	getTripExports: (params: any) => api.get(`${API_URL}/${TRANSPORT_TRIP_EXPORTED}`, params),
	getCodeXNKfromERp: (params: any) => api.get(`${API_URL}/${TRANSPORT_TRIP_EXPORTED}/from-erp`, params),
	getLogBill: (params: any) => api.get(`${API_URL}/${TRANSPORT_TRIP_EXPORTED}/log-bill`, params),

	getListLogPartner: (params: any) => api.get(`${API_URL}/${API_PARTNER_LOG}`, params),

	///RMS
	getDepartmentBlock: (params: any) => api.get(`${API_URL}/${API_DEPARTEMEN_BLOCK}`, params),
	getCareer: (params: any) => api.get(`${API_URL}/${API_CAREER}`, params),
	createCareer: (params: any) => api.post(`${API_URL}/${API_CAREER}`, params),
	updateCareer: (id: any, params: any) => api.put(`${API_URL}/${API_CAREER}/${id}`, params),
	careerDetail: (id: any) => api.get(`${API_URL}/${API_CAREER}/${id}`),
	getDepartment: (params: any) => api.getPrivate(`${API_URL}/${API_DEPARTMENT}`, params),
	updateRoleFunction: (params: any) => api.post(`${API_URL}roles/update-funct-role`, params),
	getRolFunction: (id:any,params: any) => api.get(`${API_URL}${API_ROLES}/${API_ROLES_FUNCTION}/${id}`, params),
	getBanner: (params: any) => api.get(`${API_URL}/${API_BANNER}`, params),
	getPage: (params: any) => api.get(`${API_URL}/${API_PAGES}`, params),
	createBanner:  (params: any) => api.post(`${API_URL}/${API_BANNER}`, params),
	getBannerDetails: (id: any) => api.get(`${API_URL}/${API_BANNER}/${id}`),
	updateBanner: (id: any, params: any) => api.put(`${API_URL}/${API_BANNER}/${id}`, params),
	getRecruimentTicket: (params: any) => api.get(`${API_URL}/${API_RECRUIMENT_TICKET}`, params),
	getWorkLocation: (params: any) => api.get(`${API_URL}/${API_WORKLOCATION}`, params),
	getPositions: (params: any) => api.getPrivate(`${API_URL}/${API_POSITION}`, params),
	createRecruimentTicket: (params: any) => api.post(`${API_URL}/${API_RECRUIMENT_TICKET}`, params),
	recruimentTicketDetails: (id: any) => api.get(`${API_URL}/${API_RECRUIMENT_TICKET}/${id}`),
	updateRecruimentTicket: (id: any, params: any) => api.put(`${API_URL}/${API_RECRUIMENT_TICKET}/${id}`, params),
	getPostType: (params: any) => api.get(`${API_URL}/${API_POSTTYPE}`, params),
	createPostNews: (params: any) => api.post(`${API_URL}/${API_POSTNEWS}`, params),
	getPostNews: (params: any) => api.get(`${API_URL}/${API_POSTNEWS}`, params),
	updatePostNews: (id: any, params: any) => api.put(`${API_URL}/${API_POSTNEWS}/${id}`, params),
	postNewsDetails: (id: any) => api.get(`${API_URL}/${API_POSTNEWS}/${id}`),
	getEmailField: (params: any) => api.get(`${API_URL}/${API_EMAIL_FIELD}`, params),
	getCheckInYep: (params: any) => api.get(`${API_URL}/${API_CHECKIN_YEP}`, params),
	createCheckinYep:  (params: any) => api.post(`${API_URL}/${API_CHECKIN_YEP}`, params),
	getCheckinYepDetails: (id: any) => api.get(`${API_URL}/${API_CHECKIN_YEP}/${id}`),
	updateYepCheckin: (id: any, params: any) => api.put(`${API_URL}/${API_CHECKIN_YEP}/${id}`, params),
	importYEP: (formData: any) => api.postMultipart(`${API_URL}/${API_CHECKIN_YEP}/import`, formData),
	createEmailField:  (params: any) => api.post(`${API_URL}/${API_EMAIL_FIELD}`, params),
	getEmailFieldId: (id: any) => api.get(`${API_URL}/${API_EMAIL_FIELD}/${id}`),
	updateEmailField: (id: any, params: any) => api.put(`${API_URL}/${API_EMAIL_FIELD}/${id}`, params),
	createEmailTemplate:  (params: any) => api.post(`${API_URL}/${API_EMAIL}`, params),
	getEmailTemplate: (params: any) => api.get(`${API_URL}/${API_EMAIL}`, params),
	updateEmailTemplate: (id: any, params: any) => api.put(`${API_URL}/${API_EMAIL}/${id}`, params),
	getEmailTemplateDetails: (id: any) => api.get(`${API_URL}/${API_EMAIL}/${id}`),
	getEducation: (params: any) => api.get(`${API_URL}/${API_EDUCATION}`, params),
	createRecruimentNews:  (params: any) => api.post(`${API_URL}/${API_RECRUIMENT_NEW}`, params),
	getRecruimentNews: (params: any) => api.get(`${API_URL}/${API_RECRUIMENT_NEW}`, params),
	createRecruimentNewsSeo:  (params: any) => api.post(`${API_URL}/${API_RECRUIMENT_NEW_SEO}`, params),
	getRecruimentNewsDetails: (id: any) => api.get(`${API_URL}/${API_RECRUIMENT_NEW}/${id}`),
	getRecruimentNewsSeoDetails: (id: any) => api.get(`${API_URL}/${API_RECRUIMENT_NEW_SEO}/${id}`),
	updateRecruimentNews: (id: any, params: any) => api.put(`${API_URL}/${API_RECRUIMENT_NEW}/${id}`, params),
	getRecruimentNewsSeo: (params: any) => api.get(`${API_URL}/${API_RECRUIMENT_NEW_SEO}`,params),
	updateRecruimentNewsSeo: (id: any, params: any) => api.put(`${API_URL}/${API_RECRUIMENT_NEW_SEO}/${id}`, params),
	createUserOrg: (params: any) => api.post(`${API_URL}/${API_USERS_ORG}`, params),
	getUserOrg: (params: any) => api.get(`${API_URL}/${API_USERS_ORG}`, params),
	updateUserOrg: (id: any, params: any) => api.put(`${API_URL}/${API_USERS_ORG}/${id}`, params),
	getProfileList: (params: any) => api.get(`${API_URL}/${API_CANDIDATE}`, params),
	getProfileListAcept: (params: any) => api.get(`${API_URL}/${API_CANDIDATE}/list`, params),
	getCandidateDetails: (id: any) => api.get(`${API_URL}/${API_CANDIDATE}/${id}`),
	logOut: (params: any) => api.post(`${API_URL}${API_LOGOUT}`, params),
	updateProfileCandidate: (id: any, params: any) => api.put(`${API_URL}/${API_CANDIDATE_PROFILE}/${id}`, params),
	updateCandidate: (id: any, params: any) => api.put(`${API_URL}/${API_CANDIDATE}/${id}`, params),
	createCandidateLog: (params: any) => api.post(`${API_URL}/${API_CANDIDATE_LOG}`, params),
	getCandidateLog: (params: any) => api.get(`${API_URL}/${API_CANDIDATE_LOG}`,params),
	createCandidateEvalute: (params: any) => api.post(`${API_URL}/${API_CANDIDATE_EVALUTE}`, params),
	getCandidateEvalute: (params: any) => api.get(`${API_URL}/${API_CANDIDATE_EVALUTE}`,params),
	updateCandidateEvalute: (id: any, params: any) => api.put(`${API_URL}/${API_CANDIDATE_EVALUTE}/${id}`, params),
	getLevel: (params: any) => api.get(`${API_URL}/${API_LEVEL}`,params),
	createCandidateResult: (params: any) => api.post(`${API_URL}/${API_CANDIDATERESULT}`, params),
	getCandidateResult: (params: any) => api.get(`${API_URL}/${API_CANDIDATERESULT}`,params),
	getStatusProfile: (params: any) => api.get(`${API_URL}/${API_STATUS_PROFILE}`, params),
	sendEmail: (params: any) => api.post(`${API_URL}/${API_EMAIL}/send`, params),
	getPostOffices: (params: any) => api.post(`${API_URL_ERP}/${API_POSTOFFICE}`, params,generatorToken(params)),
	

	/// USER CENTER
	getApps: (params: any) => api.get(`${API_URL}/${API_APPS}`, params),
	createApps:  (params: any) => api.post(`${API_URL}/${API_APPS}`, params),
	getAppsDetails: (id: any) => api.get(`${API_URL}/${API_APPS}/${id}`),
	updateUserApps: (id: any, params: any) => api.put(`${API_URL}/${API_USERAPPS}/${id}`, params),
	createUserApps:  (params: any) => api.post(`${API_URL}/${API_USERAPPS}`, params),
	getOrg: (params: any) => api.getPrivate(`${API_URL}/${API_ORG}`, params),
	getUsersGroups: (params: any) => api.get(`${API_URL}/${API_USER_GROUPS}`, params),
	updateUserGroups: (id: any, params: any) => api.put(`${API_URL}/${API_USER_GROUPS}/${id}`, params),
	createUserGroups:  (params: any) => api.post(`${API_URL}/${API_USER_GROUPS}`, params),
	getUsersERPs: (params: any) => api.get(`${API_URL}/${API_USER_ERP}`, params),
	createUserErps:  (params: any) => api.post(`${API_URL}/${API_USER_ERP}`, params),
	getPostOffice: (params: any) => api.getPrivate(`${API_URL}/${API_POST_OFFICE}`, params),
	getSubDepartment: (params: any) => api.getPrivate(`${API_URL}/${API_SUB_DEPARTMENT}`, params),
	updateUserErp: (params: any) => api.put(`${API_URL}/${API_USER_ERP}/update`, params),
	getBills: (params: any) => api.get(`${API_URL}${API_BILLS}`, params),
	getInWarehouse: (params: any) => api.get(`${API_URL}${API_WAREHOUSE}`, params),
	getProvinces: (params: any) => api.get(`${API_URL}${API_PROVINCES}`, params),
	getDistricts: (params: any) => api.get(`${API_URL}${API_DISTRICTS}`, params),
	getWards: (params: any) => api.get(`${API_URL}${API_WARDS}`, params),

	getServices: (params: any) => api.get(`${API_URL}${API_SERVICES}`, params),
	getGoodTypes: (params: any) => api.get(`${API_URL}${API_GOOD_TYPES}`, params),
	getPartners: (params: any) => api.get(`${API_URL}${API_PARTNERS}`, params),
	getPartnerAddresses: (id:any, params: any) => api.get(`${API_URL}${API_PARTNER_ADDRESS}/${id}`, params),
	createBill:  (params: any) => api.post(`${API_URL}${API_CREATE_BILL}`, params),
	createOperationWH:  (params: any) => api.post(`${API_URL}${API_CREATE_OPERATION_WH}`, params),
	getUsers:  (params: any) => api.get(`${API_URL}${API_GET_USERS}`, params),
	getWarehouseList: (params: any) => api.get(`${API_URL}${API_GET_WAREHOUSE_LIST}`, params),
	getDetailedOperationWH: (id: any,params: any) => api.get(`${API_URL}${API_CREATE_OPERATION_WH}/${id}`, params),
	scanBillInOut: (params: any) => api.post(`${API_URL}${API_SCAN_ONE_BILL_IN_OUT}`, params),
	completeOperationWH: (id: any, params: any) => api.put(`${API_URL}${API_COMPLETE_OPERATION_WH}/${id}`, params),
	updateOperationWH: (id: any, params: any) => api.put(`${API_URL}${API_UPDATE_OPERATION_WH}/${id}`, params),
	printBills: (params: any) => api.getDowload(`${API_URL}${API_PRINT_BILLS}`, params),
	fetchSearchEs: (id: any,params: any) => api.get(`${API_URL}${SEARCHES}/${id}`, params),
	deleteInOutBills: (params: any) => api.post(`${API_URL}${API_DELETE_BILLS_IN_OUT}`, params),
	cancelBill: (params: any) => api.post(`${API_URL}${API_CANCEL_BILL}`, params),
	showByCode: (billCode:any,params: any) => api.get(`${API_URL}${API_SHOW_BY_CODE}/${billCode}`, params),


	//TODO: PRICE SERVICE
	getPriceList: (params: any) => api.get(`${API_PRICE_URL}${API_GET_PRICE_LIST}`, params),
	createPriceList:  (params: any) => api.post(`${API_PRICE_URL}${API_CREATE_PRICE_LIST}`, params),
	showPriceList:  (priceListId: any,params: any) => api.get(`${API_PRICE_URL}${API_GET_PRICE_LIST}/${priceListId}`, params),
	updatePriceList:  (priceListId: any,params: any) => api.put(`${API_PRICE_URL}${API_CREATE_PRICE_LIST}/${priceListId}`, params),
	getPriceDetailList:  (priceListId: any,params: any) => api.get(`${API_PRICE_URL}${API_GET_PRICE_DETAIL_LIST}/${priceListId}`, params),
	getZones: (params: any) => api.get(`${API_PRICE_URL}${API_ZONES}`, params),
	createPriceDetail:  (priceListId: any,params: any) => api.post(`${API_PRICE_URL}${API_GET_PRICE_DETAIL_LIST}/${priceListId}`, params),
	updatePriceDetail:  (priceListDetailId: any,params: any) => api.put(`${API_PRICE_URL}${API_GET_PRICE_DETAIL_LIST}/${priceListDetailId}`, params),
};

export const _cdn_url = {
	production: 'https://cdn.ntlogistics.vn/',
	development:'https://cdndev.ntlogistics.vn/',
	local: 'https://cdndev.ntlogistics.vn/',
	url: 'https://cdndev.ntlogistics.vn/',
	token: '2a1da92fda15eb7ecaf0501b885327176543cc7334e4bb2bfb423e497869a4d9'
  };
/*-----------------------------UPLOAD FILE-----------------------------------*/
export function uploadFile(file:any, callback:any) {
	let _apiUrl = CND_URL +'upload-files/';
	let formData = new FormData();
	axios.defaults.headers.common["auth-uuid"] = REACT_APP_CDN_REACT_TOKEN;
	formData.append('object', 'rms');
	formData.append('object_id', '1');
	formData.append("files", file);
  
	axios
	  .post(_apiUrl, formData, {
		headers: {
		  "Content-Type": "multipart/form-data",
  
		},
	  })
	  .then(function (response) {
		callback(response.data);
	  })
	  .catch((error) => {
		throw error;
	  });
  }
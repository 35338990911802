export const platforms = [
  {
    value: "",
    label: "Tất cả",
  },
  {
    value: "Tiki",
    label: "Tiki",
  },
  {
    value: "Lazada",
    label: "Lazada",
  },
  {
    value: "Shopee",
    label: "Shopee",
  },
  {
    value: "Sendo",
    label: "Sendo",
  },
  {
    value: "Senhong",
    label: "Sen Hồng",
  },
  {
    value: "Zalo",
    label: "Zalo",
  },
];

export const dataTypes = [
  {
    value: 1,
    label: "Đơn hàng",
  },
  {
    value: 2,
    label: "Sản phẩm",
  },
  {
    value: 3,
    label: "Thông tin điện tử",
  },
  {
    value: 4,
    label: "Hàng hoá",
  },
  {
    value: 5,
    label: "Thiết bị điện tử",
  },
];

export const dataRuntimes = [
  {
    value: 1,
    label: "2 phút",
  },
  {
    value: 2,
    label: "5 phút",
  },
  {
    value: 3,
    label: "10 phút",
  },
  {
    value: 4,
    label: "15 phút",
  },
];

export const carStatus:any = {
  0: 'Xe đang hoạt động',
  2: 'Dừng xe',
  5: 'Mất tín hiệu',
  10:'Dừng xe không tắt máy'
}

export const WH_OPERATION_TYPE : any = {
  "PTW": "Nhập kho",
  "WTW": "Xuất kho đến kho", //Kho-kho
  "WTD": "Xuất kho trả hàng", //Xuất kho th
  "DTW": "Nhập kho không phát được", //Xuất kho th
}

export const STATUS_BILL_LIST = [
  {
    id: 1,
    bs_code: "OPN",
    bs_name: "Chờ lấy hàng",
    bs_name_tracking: "Chờ lấy hàng",
    status: "A",
    desc: "Hàng mới phát sinh",
  },
  {
    id: 2,
    bs_code: "LPC",
    bs_name: "Đã lấy hàng",
    bs_name_tracking: "Đã lấy hàng",
    status: "A",
    desc: "Đã lấy hàng",
  },
  {
    id: 3,
    bs_code: "CIW",
    bs_name: "Nằm tại kho",
    bs_name_tracking: "Đang vận chuyển",
    status: "A",
    desc: "Hàng đã nhập kho",
  },
  {
    id: 4,
    bs_code: "DIT",
    bs_name: "Chuyển kho",
    bs_name_tracking: "Đang vận chuyển",
    status: "A",
    desc: "Hàng đang xuất kho - kho",
  },
  {
    id: 5,
    bs_code: "EOD",
    bs_name: "Đang giao hàng",
    bs_name_tracking: "Đang giao hàng",
    status: "A",
    desc: "Xuất kho trả hàng",
  },
  {
    id: 6,
    bs_code: "FUD",
    bs_name: "Không giao được",
    bs_name_tracking: "Đang giao hàng",
    status: "A",
    desc: "NULL",
  },
  {
    id: 7,
    bs_code: "GBV",
    bs_name: "Hủy",
    bs_name_tracking: "Hủy",
    status: "A",
    desc: "NULL",
  },
  {
    id: 8,
    bs_code: "NRT",
    bs_name: "Đang hoàn hàng",
    bs_name_tracking: "Đang hoàn hàng",
    status: "A",
    desc: "NULL",
  },
  {
    id: 9,
    bs_code: "MRC",
    bs_name: "Đã hoàn hàng",
    bs_name_tracking: "Đã hoàn hàng",
    status: "A",
    desc: "NULL",
  },
  {
    id: 10,
    bs_code: "FBC",
    bs_name: "Đã giao hàng",
    bs_name_tracking: "Đã giao hàng",
    status: "A",
    desc: "NULL",
  },
];

export const STATUS_BILL_OBJ : any = {
  1: {
    id: 1,
    bs_code: "OPN",
    bs_name: "Chờ lấy hàng",
    bs_name_tracking: "Chờ lấy hàng",
    status: "A",
    desc: "Hàng mới phát sinh",
    color:"blue"
  },
  2: {
    id: 2,
    bs_code: "LPC",
    bs_name: "Đã lấy hàng",
    bs_name_tracking: "Đã lấy hàng",
    status: "A",
    desc: "Đã lấy hàng",
    color: "green"
  },
  3: {
    id: 3,
    bs_code: "CIW",
    bs_name: "Nằm tại kho",
    bs_name_tracking: "Đang vận chuyển",
    status: "A",
    desc: "Hàng đã nhập kho",
    color: "orange"
  },
  4: {
    id: 4,
    bs_code: "DIT",
    bs_name: "Chuyển kho",
    bs_name_tracking: "Đang vận chuyển",
    status: "A",
    desc: "Hàng đang xuất kho - kho",
    color: "orange"
  },
  5: {
    id: 5,
    bs_code: "EOD",
    bs_name: "Đang giao hàng",
    bs_name_tracking: "Đang giao hàng",
    status: "A",
    desc: "Xuất kho trả hàng",
    color: "orange"
  },
  6: {
    id: 6,
    bs_code: "FUD",
    bs_name: "Không giao được",
    bs_name_tracking: "Đang giao hàng",
    status: "A",
    desc: "NULL",
    color: "red"
  },
  7: {
    id: 7,
    bs_code: "GBV",
    bs_name: "Hủy",
    bs_name_tracking: "Hủy",
    status: "A",
    desc: "NULL",
    color: "red"
  },
  8: {
    id: 8,
    bs_code: "NRT",
    bs_name: "Đang hoàn hàng",
    bs_name_tracking: "Đang hoàn hàng",
    status: "A",
    desc: "NULL",
    color: "orange"
  },
  9: {
    id: 9,
    bs_code: "MRC",
    bs_name: "Đã hoàn hàng",
    bs_name_tracking: "Đã hoàn hàng",
    status: "A",
    desc: "NULL",
    color: "green"
  },
  10: {
    id: 10,
    bs_code: "FBC",
    bs_name: "Đã giao hàng",
    bs_name_tracking: "Đã giao hàng",
    status: "A",
    desc: "NULL",
    color: "green"
  },
};

export const STATUS_WH_OBJ : any = {
  "O": {
    name: "Mới",
  },
  "P": {
    name: "Ghi tạm",
    color: "blue"
  },
  "C": {
    name: "Hoàn thành",
    color: "green"
  },
  "D": {
    name: "Ghi tạm",
    color: "red"
  },
}

export const CALC_TYPE_LIST : any =[
  {
    id: 1,
    code: "CT09",
    calc_type_name: "Cước Vận Chuyển (Tính Từ TL Quy Đổi)"
  },
  {
    id: 2,
    code: "CT10",
    calc_type_name: "Cước Vận Chuyển (Tính Từ TL Thực)"
  },
  {
    id: 3,
    code: "CT11",
    calc_type_name: "Cước Vận Chuyển (Tính Từ Số Kiện)"
  }
]


export const APPLY_TYPE_LIST : any =[
  {
    id: 1,
    code: "AT89",
    apply_type_name: "Giá cố định"
  },
  {
    id: 2,
    code: "AT99",
    apply_type_name: "Giá lũy tiến"
  }
]